import axios from "axios";

const url = process.env.REACT_APP_PRISMA_URL;

// Receives a list of userID's as it's argument
export async function getOrganizationsByUsers(excludedUserID, userList) {
  if (!userList.constructor === Array) return null;

  // Dynamic creation of the where clause based on userList length
  let where = `{OR: [`;
  for (let user of userList) {
    if (user.id === excludedUserID) continue;
    where += `{users_some: { id: "${user.id}" }},`;
  }
  where += `]}`;

  const result = await axios({
    url: url,
    headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    method: "POST",
    data: {
      query: `
      query {
        organizations(where: ${where}){
          id
        }
      }
      `,
    },
  });
  return result.data.data.organizations;
}

export async function getOrganizationAIData(orgId) {
  const result = await axios({
    url: url,
    headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    method: "POST",
    data: {
      query: `
      query {
        organization(id: "${orgId}") {
          id
          name
          from
          site
          inboundPhone
          industry {
            id
            name
          }
          customDomain
          users {
            id
            fname
            lname
            email
            phone
            role
            subAccount {
              id
              name
            }
          }
          subAccounts {
            id
            name
            users {
              id
              fname
              lname
              email
              role
            }
          }
          organizationGoal
        }
      }
      `,
    },
  });
  return result.data.data.organization;
}

export async function getOrganizationDataEntry() {
  const result = await axios({
    url: url,
    headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    method: "POST",
    data: {
      query: `
      query {
        organization(id: "${localStorage.getItem("organizationId")}") {
          industry {
            id
            name
          }
          organizationGoal
          site
        }
      }
      `,
    },
  });
  return result.data.data.organization;
}
