import gql from "graphql-tag";

export default gql`
  mutation addVerificationCode($email: String, $vCode: String) {
    addVerificationCode(email: $email, vCode: $vCode) {
      id
      mfaType
      phone
      organization {
        id
        from
        billingCycle
      }
    }
  }
`;
