import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
} from "reactstrap";
import { FaBook, FaCogs, FaFileAlt } from "react-icons/fa";
import React, { Component } from "react";
import { compose, graphql } from "react-apollo";

import { Badge } from "@material-ui/core";
import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
import MessagesConsumption from "../../components/MessagesConsumption/MessagesConsumption";
import Notifications from "../../components/Notifications/Notifications";
import PropTypes from "prop-types";
import UpdateNotification from "../../mutations/updateNotification";
import UserNotifications from "../../queries/UserNotifications";
import logo from "../../assets/img/brand/logo_tagline.png";
import { styled } from "@material-ui/core/styles";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const CustomBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    backgroundColor: "var(--lw-blue-color)", // Change this to your desired color
    color: "white", // Change this to your desired text color
    transform: "scale(0.8) translate(50%, -30%)", // Change this to your desired size
  },
});
class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotifications: false,
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    // Add socket for notifications.refetch()
    const { socket } = this.props;
    if (!socket) return;

    socket.on("update-notifications", () => {
      this.props.notifications.refetch();
    });

    return () => {
      socket.off("update-notifications");
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  render() {
    const { data, notifications, updateNotification } = this.props;
    if (!localStorage.getItem("token")) {
      this.props.history.push(`/login`);
    }
    if (data.loading) {
      return <div></div>;
    }

    // Gets all the notifications from the db
    const notificationsData = notifications.notifications
      ? notifications.notifications
      : [];

    const handleShowNotifications = () => {
      setTimeout(() => {
        this.setState({ showNotifications: !this.state.showNotifications });
      }, 250);
      this.props.notifications.refetch();
    };

    const handleReadAll = () => {
      const notificationsId = notificationsData
        .filter((notification) => notification.isRead === false)
        .map((notification) => notification.id);

      notificationsId.forEach((id) => {
        let variables = {
          id: id,
          isRead: true,
        };

        updateNotification({ variables });
      });

      this.props.notifications.refetch();
    };

    const handleNotificationReadChange = async (isReadButton) => {
      const selectedNotificationId = isReadButton.target.id;
      const selectedNotification = notificationsData.filter(
        (notification) => notification.id === selectedNotificationId
      );
      const selectedNotificationIsRead = selectedNotification[0].isRead;

      let variables = {
        id: selectedNotificationId,
        isRead: !selectedNotificationIsRead,
      };

      await updateNotification({ variables });
      this.props.notifications.refetch();
    };

    return (
      <React.Fragment>
        <AppSidebarToggler
          style={{ outline: "none" }}
          className="d-lg-none"
          display="md"
          mobile
        />
        <AppNavbarBrand
          className="logo-mobile"
          full={{ src: logo, width: 200, alt: "LeadWire Logo" }}
          minimized={{ src: logo, width: 30, alt: "LeadWire Logo" }}
          href="/dashboard"
        />
        <Nav className="header-nav-menu ml-auto" navbar>
          <CustomBadge badgeContent="New">
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropdown}
            >
              <DropdownToggle className="custom-dropdown-toggle">
                Developers
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    this.props.history.push(`/api-logs`);
                  }}
                >
                  <FaFileAlt style={{ color: "grey" }} />
                  <span style={{ marginLeft: "0.85rem" }}>API Logs</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.props.history.push(`/integrations`);
                  }}
                >
                  <FaCogs style={{ color: "grey" }} />
                  <span style={{ marginLeft: "0.85rem" }}>Integrations</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    process.env.REACT_APP_LEADWIRE_API.includes("dev")
                      ? window.open(
                          "https://leadwire-api-dev.herokuapp.com",
                          "_blank"
                        )
                      : window.open(
                          "https://developers.leadwireapp.io",
                          "_blank"
                        );
                  }}
                >
                  <FaBook style={{ color: "grey" }} />
                  <span style={{ marginLeft: "0.85rem" }}>Docs</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </CustomBadge>
          <MessagesConsumption withStyle />
          <DefaultHeaderDropdown help history={this.props.history} />
          <Notifications
            showNotification={this.state.showNotifications}
            handleShowNotifications={handleShowNotifications}
            handleReadAll={handleReadAll}
            notifications={notificationsData}
            handleNotificationReadChange={handleNotificationReadChange}
            onClickOutside={() => {
              this.setState({ showNotifications: false });
            }}
          />
          <DefaultHeaderDropdown accnt history={this.props.history} />
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default compose(
  graphql(UserNotifications, {
    options: (props) => ({
      variables: { id: props.data.me ? props.data.me.id : "" },
    }),
    name: "notifications",
  }),
  graphql(UpdateNotification, { name: "updateNotification" })
)(DefaultHeader);
