import "./Notifications.css";

import React, { useEffect, useRef, useState } from "react";

import Icon from "@material-ui/core/Icon";
import { Markup } from "interweave";
import NotificationsEmptyImage from "../../assets/img/notificationsEmpty.png";
import moment from "moment";

const Notifications = ({
  showNotification,
  handleShowNotifications,
  handleReadAll,
  notifications,
  handleNotificationReadChange,
  onClickOutside,
}) => {
  const [notificationsArr, setNotificationsArr] = useState([]);

  const notificationsRef = useRef(null);

  useEffect(() => {
    setNotificationsArr(
      notifications.sort((n1, n2) =>
        moment(n2.createdAt).diff(moment(n1.createdAt))
      )
    );
  }, [notifications]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        onClickOutside && onClickOutside();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  const unReadLength = () =>
    notificationsArr.filter((notification) => notification.isRead === false)
      .length;

  const handleClickBellIcon = () => {
    handleReadAll();
    handleShowNotifications();
  };

  const dateDiffrence = (createdAt) => {
    const current_date = moment(new Date());
    const created_at = moment(createdAt);
    const timeDiffrence = moment.duration(current_date.diff(created_at));
    const hoursDiff = timeDiffrence.asHours();
    const minuteDiff = timeDiffrence.asMinutes();

    if (hoursDiff >= 48) {
      return ["older", createdAt.slice(0, 10)];
    }
    if (hoursDiff >= 24) {
      return ["yesterday", createdAt.slice(0, 10)];
    }
    if (minuteDiff < 1) {
      return ["today", timeDiffrence._data.seconds + " seconds ago"];
    }
    if (hoursDiff < 1) {
      return ["today", timeDiffrence._data.minutes + " minutes ago"];
    }

    return ["today", timeDiffrence._data.hours + " hours ago"];
  };

  const dateComponent = () => {
    const older = [];
    const yesterday = [];
    const today = [];

    notificationsArr.forEach((notification) => {
      const difference = dateDiffrence(notification.createdAt);
      if (difference[0] === "older") {
        older.push(notificationFormatter(notification));
      } else if (difference[0] === "yesterday") {
        yesterday.push(notificationFormatter(notification));
      } else {
        today.push(notificationFormatter(notification));
      }
    });

    return (
      <div>
        {today.length ? (
          <>
            {" "}
            <h6 className="calendar-dividers">TODAY</h6> {today}{" "}
          </>
        ) : null}
        {yesterday.length ? (
          <>
            {" "}
            <h6 className="calendar-dividers" style={{ paddingTop: "26px" }}>
              YESTERDAY
            </h6>{" "}
            {yesterday}{" "}
          </>
        ) : null}
        {older.length ? (
          <>
            {" "}
            <h6 className="calendar-dividers" style={{ paddingTop: "26px" }}>
              OLDER
            </h6>{" "}
            {older}{" "}
          </>
        ) : null}
      </div>
    );
  };

  const notificationFormatter = (notification) => {
    return (
      <div className="single-container" key={notification.id}>
        <article className="article-container">
          <span>
            <a
              href={notification.link}
              onClick={handleNotificationReadChange}
              style={{ color: "black", textDecoration: "none" }}
              id={notification.id}
            >
              <span className="text" id={notification.id}>
                <Markup content={notification.text} />
              </span>
            </a>
            <div className="date-container">
              <span className="date">
                <i className="fa fa-calendar" />{" "}
                {dateDiffrence(notification.createdAt)[1]}
              </span>
              <span className="mark-container">
                <button
                  className="button"
                  onClick={handleNotificationReadChange}
                  id={notification.id}
                >
                  <Icon
                    style={{
                      fontSize: "16px",
                      color: notification.isRead ? "var(--lw-blue-color)" : "",
                    }}
                    id={notification.id}
                  >
                    done_all
                  </Icon>
                </button>
              </span>
            </div>
          </span>
        </article>
        <hr style={{ margin: "0" }} />
      </div>
    );
  };

  return (
    <div ref={notificationsRef}>
      <span className="bell-icon" onClick={handleClickBellIcon}>
        {unReadLength() !== 0 ? (
          unReadLength() > 99 ? (
            <div className="red-circle" style={{ fontSize: "9.4px" }}>
              <strong>99+</strong>
            </div>
          ) : (
            <div className="red-circle">
              <strong>{unReadLength()}</strong>
            </div>
          )
        ) : null}
        <i className="fa fa-bell" />
      </span>
      {showNotification && (
        <section className="notification-container">
          <h4
            style={{
              paddingLeft: "20px",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            Notifications
          </h4>
          <hr style={{ margin: "0" }} />
          {!notificationsArr.length ? (
            <div>
              <img
                className="image-empty"
                src={NotificationsEmptyImage}
                alt="Notifications Empty"
              />
              <span className="text-empty">
                No <strong> new </strong> notifications at the moment.
              </span>
            </div>
          ) : (
            dateComponent()
          )}
        </section>
      )}
    </div>
  );
};

export default Notifications;
