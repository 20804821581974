import { IMAGE_URL_REGEX } from "./constants";
import moment from "moment";

/**
 * Verifies if an object is empty.
 *
 * @param {object} obj
 */
function isEmpty(obj) {
  for (var i in obj) {
    return false;
  }
  return true;
}

/**
 * Capitalizes first letter of string.
 *
 * @param {*} string
 * @returns
 */
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Divides array into chunks
 *
 * @param {*} arr
 * @param {*} size
 * @returns
 */
function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

/**
 *
 * @param {*} date - date object.
 * @param {*} format - format string.
 * @returns
 */
function dateFormatter(date, format) {
  return moment(date).format(format);
}

function downloadCanvasAsPNG(id, filename) {
  let downloadLink = document.createElement("a");
  downloadLink.setAttribute("download", filename);
  let canvas = document.getElementById(id);
  let dataURL = canvas.toDataURL("image/png");
  let url = dataURL.replace(
    /^data:image\/png/,
    "data:application/octet-stream"
  );
  downloadLink.setAttribute("href", url);
  downloadLink.click();
}

function isValidImageURL(url) {
  return IMAGE_URL_REGEX.test(url) || url === "";
}

const getCorrectCampaignTypeFormat = (type) => {
  if (type === "message" || type === "SMS") return "SMS";
  else if (type.toLowerCase() === "survey") return "Survey";
  return "MMS";
};

const validatePassword = (password) => {
  const minLength = 12;
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/;

  if (password.length < minLength) {
    return `Password must be at least ${minLength} characters long.`;
  }
  if (!regex.test(password)) {
    return "Password must include uppercase, lowercase letters, numbers, and special characters.";
  }
  return "";
};

export {
  isEmpty,
  capitalize,
  chunkArrayInGroups,
  dateFormatter,
  downloadCanvasAsPNG,
  isValidImageURL,
  getCorrectCampaignTypeFormat,
  validatePassword,
};
