export const SURVEY_INSTRUCTIONS =
  "Create a survey campaign by adding up to three questions, select the answer type for each question – multiple choice, rating or open-ended and add the expected answers.  Leadwire confirms each question is answered correctly before submitting surveys.";

export const AUDIENCES_CLOSED_HREF = "audiences/closed";

export const CAMPAIGNS_HREF = "campaigns";

export const REPORT_STOP_HREF = "report/stop";

export const CUSTOM_FIELDS_HREF = "customfields";

export const NEW_CAMPAIGN_HREF = "campaign";

export const IMAGE_URL_REGEX = /^https?:\/\/(?:[a-z-]+\.)+[a-z]{2,6}(?:\/[^/#?]+)+\.(?:jpe?g|gif|png)$/;

export const COUPON_PORTAL = "/c";

export const COUPON_VERIFY = "/vc/:hash";

export const ADMIN_HREF = "/admin";

export const PLANS_HREF = "/plans";

export const DASHBOARD_HREF = "/dashboard";

export const AUDIENCES_HREF = "/audiences/:newList";

export const AUDIENCE_COUPONS_HREF = "/audience/:id/coupons";

export const AUDIENCE_HREF = "/audience/:id";

export const ORGANIZATION_HREF = "/org/:id";

export const REPORTS_HREF = "/reports";

export const OLD_REPORTS_HREF = "/oldreports";

export const SURVEY_REPORT_HREF = "/report/:id/:surveyId";

export const REPORT_HREF = "/report/:id/";

export const COUPON_REPORT_HREF = "/creport/:id/";

export const OPTOUT_REPORT_HREF = "/optout";

export const SURVEY_HREF = "/survey/:id/:surveyId";

export const SUPER_ADMIN_HREF = "/sadmin";

export const CAMPAIGN_BY_ID_HREF = "/campaign/:id";

export const CAMPAIGN_HREF = "/campaign";

export const CAMPAIGNS_PATH_HREF = "/campaigns";

export const CUSTOM_FIELDS_PATH_HREF = "/customfields";

export const IMPORT_CONTACTS_PATH_HREF = "/audience/:id/importcontacts";

export const IMPORT_OPT_OUT_PATH_HREF = "/audience/:id/optoutimport";

export const INBOX_HREF = "/inbox";

export const OPTIN_OPTIONS_HREF = "/audience/:id/optin";

export const API_LOGS_HREF = "/api-logs";

export const INTEGRATIONS_HREF = "/integrations";

export const DATA_ENTRY_HREF = "/data-entry";
